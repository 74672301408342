import React from "react";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
// MUI ICONS
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import PeopleIcon from "@material-ui/icons/People";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
// REACT ROUTER
import { Link } from "react-router-dom";
// FIREBASE
import firebase from "../../../config/fbConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList(props) {
  const classes = useStyles();

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("user signed out");
      });
  };

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItem>
        <Divider />

        <ListItem component={Link} to={`/messages`} button>
          <ListItemIcon>
            <AnnouncementIcon />
          </ListItemIcon>
          <ListItemText primary="Notificações" />
        </ListItem>

        <ListItem component={Link} to={`/posts`} button>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Páginas" />
        </ListItem>

        <ListItem component={Link} to={`/users`} button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>

        <ListItem component={Link} to={`/configuration`} button>
          <ListItemIcon>
            <SettingsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Configuração" />
        </ListItem>

        <ListItem onClick={handleLogout} button className="logout">
          <ListItemIcon>
            <ExitToAppTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </>
  );
}

import React from "react";
// MUI
import { Typography, Divider } from "@material-ui/core";
import Container from "@material-ui/core/Container";
// IMAGES
import imageVerified from "../../../../../images/verified.png";

import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const verified = (
  <div>
    <img
      src={imageVerified}
      style={{
        height: "22px",
        margin: "0px 4px",
      }}
      alt="verified"
    />
  </div>
);

const CommentsView = ({ comments, handleDelete }) => {
  return (
    <div style={{ position: "relative" }}>
      {comments ? (
        <Container maxWidth="sm">
          {comments.map((comment) => {
            const date = new Date(comment.createdAt);
            const hour = ("0" + parseInt(date.getHours())).slice(-2);
            const minute = ("0" + parseInt(date.getMinutes())).slice(-2);
            return (
              <>
                <Divider />
                <div style={{ padding: "20px 10px" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexGrow: 1 }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                        gutterBottom
                      >
                        {comment.userName}
                      </Typography>
                      {comment.isAdmin ? verified : null}
                    </div>

                    <span
                      style={{ float: "right" }}
                    >{`${hour}:${minute}`}</span>
                    <div style={{ margin: "0px 8px" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(comment.id)}
                        style={{ padding: 0 }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div>{comment.body}</div>
                </div>
              </>
            );
          })}
        </Container>
      ) : null}
    </div>
  );
};

export default CommentsView;

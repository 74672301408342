import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Linkify from "react-linkify";

import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// DATE
import { convertDate } from "../../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(1),
    // margin: "auto",
    // maxWidth: 500,
    // marginBottom: 20,
    // backgroundColor: "#82ffa04f",
    padding: theme.spacing(1),
    margin: "auto",
    maxWidth: 500,
    marginBottom: 20,
    backgroundColor: "white",
  },
  image: {
    width: 48,
    height: 48,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export default function ComplexGrid({
  title,
  body,
  messageIcon,
  thumbnail,
  image,
  imageUrl,
  createdAt,
  updatedAt,
  handleDelete,
  handleOpenComments,
  openComments,
  CommentButton,
}) {
  const classes = useStyles();

  const hour = new Date(updatedAt);
  const ddmmyyyy = convertDate(new Date(updatedAt));

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
      {text}
    </a>
  );
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item>
            <ButtonBase className={classes.image}>
              {thumbnail ? (
                <img className={classes.img} alt="complex" src={messageIcon} />
              ) : (
                ""
              )}
            </ButtonBase>
          </Grid>
          <Grid item xs container>
            <Grid
              item
              xs
              container
              direction="row"
              justify="space-between"
              spacing={2}
            >
              <Grid item style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography style={{ fontWeight: "bold" }} variant="body2">
                      {title}
                    </Typography>

                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="overline"
                    >
                      {`${hour.getHours()}:${("0" + hour.getMinutes()).slice(
                        -2
                      )} - ${ddmmyyyy}`}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      aria-label="delete"
                      onClick={handleDelete}
                      style={{ padding: 0 }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Linkify componentDecorator={componentDecorator}>
                {body
                  ? body.split("\n").map((i, key) => {
                      return (
                        <Typography key={key} align="left" variant="body2">
                          {i === "" ? <br /> : i}
                        </Typography>
                      );
                    })
                  : null}
              </Linkify>
            </Grid>
          </Grid>
        </Grid>
        {imageUrl === "" ? (
          ""
        ) : (
          <Grid item>
            <img
              alt="this is the post"
              style={{ width: "100%", paddingTop: "8px" }}
              src={imageUrl}
            />
          </Grid>
        )}
        {CommentButton ? CommentButton : null}
      </Paper>
    </div>
  );
}

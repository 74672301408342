import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Image from "@material-ui/icons/Image";
import Notifications from "@material-ui/icons/Notifications";
import { yellow, blue } from "@material-ui/core/colors";
// COMPONENTS
import FabNotification from "../FabNotification";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export default function SpeedDials(props) {
  const { config, handleSetConfig, handleChange } = props;
  const [openOptions, setOpenOptions] = React.useState({
    openNotifications: false,
  });
  const handleOpenNotification = () => {
    setOpenOptions({ openNotifications: true });
  };
  const handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleImageChange = (e) => {
    const image = { target: { id: e.target.id, value: e.target.files[0] } };
    handleChange(image);
  };

  const actions = [
    {
      icon: (
        <>
          <input
            type="file"
            id="imageInput"
            onChange={handleImageChange}
            hidden
          />
          <Image
            onClick={() => handleEditPicture()}
            style={{ color: blue[500] }}
          />
        </>
      ),
      name: "Imagem",
    },
    {
      icon: (
        <Notifications
          onClick={handleOpenNotification}
          style={{ color: yellow[500] }}
        />
      ),
      name: "Notificação",
    },
  ];

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseOptions = (option, value) => {
    setOpenOptions({ [option]: value });
  };

  return (
    <>
      <FabNotification
        handleCloseOptions={handleCloseOptions}
        openNotification={openOptions.openNotifications}
        config={config}
        setConfig={handleSetConfig}
      />
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// COMPONENTS
import Actions from "../usercardadmin-components/Actions";

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
    backgroundColor: "#82ffa04f",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ user, handleEditUser }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <div
          style={{
            float: "right",
            position: "relative",
            right: "15px",
            top: "-8px",
            width: "10px",
          }}
        >
          <Actions user={user} handleEditUser={handleEditUser} />
        </div>
        <Typography variant="button">{user.handle}</Typography>
        <Typography
          display="block"
          variant="caption"
          className={classes.pos}
          color="textSecondary"
        >
          {user.email}
        </Typography>
      </Paper>
    </>
  );
}

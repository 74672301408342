import React, { Component } from "react";
// COMPONENTS
import View from "./commentform-view";
// FIREBASE
import firebase from "../../../../../config/fbConfig.js";

class Container extends Component {
  db = firebase.firestore();
  state = {
    message: "",
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleSubmit = (commentsCount) => {
    // Add a new document with a generated id.
    this.db
      .collection("comments")
      .add({
        body: this.state.message,
        messageId: this.props.message.id,
        userName: localStorage.getItem("userName"),
        createdAt: new Date().toISOString(),
        isAdmin: true,
      })
      .then((ref) => {
        // this.props.autoScroll();
        // console.log(ref);
      })
      .catch((err) => console.warn(err));

    this.db
      .collection("messages")
      .doc(this.props.message.id)

      .update({
        commentsCount: this.props.commentsCount + 1,
        createdAt: new Date().toISOString(),
      })
      .then((ref) => {
        // console.log(ref);
        // this.props.autoScroll();
      })
      .catch((err) => console.warn(err));

    this.setState({ message: "" });
  };

  render() {
    return (
      <View
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        message={this.state.message}
      />
    );
  }
}

export default Container;

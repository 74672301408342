import React, { Component } from "react";
// COMPONENTS
import View from "./commentsdialog-view";
// FIREBASE
import firebase from "../../../config/fbConfig.js";

class Container extends Component {
  db = firebase.firestore();
  state = {
    comments: [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open && !prevProps.open) {
      this.fetchComments();
    }
  }

  fetchComments() {
    // real-time listener
    this.setState({
      comments: [],
    });

    const fetchRef = this.db
      .collection("comments")
      .where("messageId", "==", this.props.messageComments.id)
      .orderBy("createdAt");

    let comments = [];
    fetchRef.onSnapshot((documentSnapshots) => {
      documentSnapshots.docChanges().forEach((change) => {
        if (change.type === "added") {
          let newComment = { ...change.doc.data(), id: change.doc.id };

          comments = [newComment, ...comments];
          this.lastVisible =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];
        }
        if (change.type === "removed") {
          console.log(change.doc.id);

          comments = this.state.comments.filter(
            (comment) => comment.id !== change.doc.id
          );
        }
      });
      this.setState({
        comments: comments,
      });
    });
  }
  render() {
    return <View {...this.props} comments={this.state.comments} />;
  }
}

export default Container;

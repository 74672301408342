import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function InputWithIcon({ icon, label, handleChange, value }) {
  const classes = useStyles();

  return (
    <TextField
      onChange={handleChange}
      value={value}
      className={classes.margin}
      id="input-with-icon-textfield"
      label={label}
      InputProps={{
        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
      }}
    />
  );
}

import React from "react";
// MUI

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";

const View = (props) => {
  const { handleOpenComments, message } = props;

  return (
    <IconButton
      aria-label="comment"
      onClick={() => handleOpenComments(true, message)}
      style={{ zIndex: 0 }}
    >
      <Badge badgeContent={message.commentsCount} color="primary">
        <ChatIcon />
      </Badge>
    </IconButton>
  );
};

export default View;

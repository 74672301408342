import React, { Component } from "react";
import CategorySelector from "./editpost-components/CategorySelector";
import EditPostContainer from "./editpost-container";
class EditPostRenderProps extends Component {
  state = {};
  render() {
    const { edit } = this.props;
    return (
      <CategorySelector
        category={
          edit.post.category !== undefined ? edit.post.category : undefined
        }
        render={(selectedId, selectedName, selector) => (
          <EditPostContainer
            edit={this.props.edit}
            selected={selectedId}
            selectedName={selectedName}
            selector={selector}
          />
        )}
      />
    );
  }
}

export default EditPostRenderProps;

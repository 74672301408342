import React from "react";
import "./styles.css";

const alphabet = [
  "#",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export default function PaginationControlled(props) {
  const [page, setPage] = React.useState(1);

  const handleClick = (e) => {
    const letter = e.target.innerText;
    setPage(letter);
    props.onClick(letter);
  };
  const classActive = (letter) =>
    letter === page ? "pagination-active" : null;

  return (
    <div id="pagination">
      {alphabet.map((letter) => (
        <div
          className={`pagination-item ${classActive(letter.toUpperCase())}`}
          onClick={handleClick}
        >
          <span>{letter.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
}

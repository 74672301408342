import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";

import TextField from "@material-ui/core/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  setOpen,
  handleChange,
  handleSave,
  id,
  label,
  value,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div style={{ padding: 8 }}>
          <TextField
            style={{ width: "100%" }}
            id={id}
            value={value}
            label={label}
            onChange={handleChange}
          />

          <Divider />
          <div style={{ textAlign: "right", padding: 16 }}>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Cancelar
            </Button>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Salvar
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

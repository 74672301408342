import firebase from "../../config/fbConfig";

var db = firebase.firestore();

export const create = (collectionName, data) => {
  return db
    .collection(collectionName)
    .add(data)
    .then(function (docRef) {
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

export const readCollection = (collectionName) => {
  let documents = [];
  const collectionRef = db.collection(collectionName);
  return collectionRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      console.log(`${doc.id} => ${doc.data()}`);
      documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
  });
};

export const readWhere = (collectionName, query) => {
  let documents = [];
  const collectionRef = db.collection(collectionName);
  return collectionRef
    .where(query.field, query.operator, query.value)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        documents.push({ id: doc.id, ...doc.data() });
      });
      return documents;
    });
};

export const readPaginatedStart = (collectionName, limit) => {
  let documents = [];
  const collectionRef = db.collection(collectionName);
  return collectionRef
    .limit(limit)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      return documents;
    });
};
export const readPaginatedContinue = async (collectionName, limit, lastDoc) => {
  if (!lastDoc) return;
  const lastDocRef = await db.collection(collectionName).doc(lastDoc.id).get();
  let documents = [];
  const collectionRef = db.collection(collectionName);
  return collectionRef
    .limit(limit)
    .startAfter(lastDocRef)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      return documents;
    });
};

export const readPaginatedCustom = (
  collectionName,
  orderBy,
  startAt,
  limit
) => {
  let documents = [];
  const collectionRef = db.collection(collectionName);
  const queryRef = collectionRef.orderBy(orderBy);
  return queryRef
    .startAt(startAt)
    .endBefore(String.fromCharCode(startAt.charCodeAt(0) + 1))
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });

      //
      return queryRef
        .startAt(startAt.toLowerCase())
        .endBefore(String.fromCharCode(startAt.toLowerCase().charCodeAt(0) + 1))
        .get()
        .then((querySnapshotLower) => {
          querySnapshotLower.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
          });
          //
          return documents;
        });
    });
};

export const readPaginatedNumbers = (collectionName, orderBy) => {
  let documents = [];
  const collectionRef = db.collection(collectionName);
  const queryRef = collectionRef.orderBy(orderBy);
  return queryRef
    .endBefore("A")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      return documents;
    });
};

export const readDoc = (collectionName, docName) => {
  const docRef = db.collection(collectionName).doc(docName);
  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
      } else {
        console.log("No such document!");
      }
      return doc;
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};

export const set = (collectionName, docName, data) => {
  const docRef = db.collection(collectionName).doc(docName);
  return docRef
    .set(data, { merge: true })
    .then(() => {
      console.log("Document successfully written!");
      return true;
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
    });
};

export const update = (collectionName, docName, data) => {
  const docRef = db.collection(collectionName).doc(docName);
  return docRef.update(data).then(() => true);
};

import React from "react";
// MUI
import Container from "@material-ui/core/Container";
// STYLES
import classes from "./login.module.css";
const LoginView = props => {
  return (
    <div className={classes.loginLayout}>
      <Container fixed>{props.children}</Container>
    </div>
  );
};

export default LoginView;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
// MUI ICONS
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import DateRangeTwoToneIcon from "@material-ui/icons/DateRangeTwoTone";

const useStyles = makeStyles({
  fab: {
    background: "transparent",
    color: "gray",
    height: 40,
    width: 40,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "lightgreen",
    },
  },
});

const ActionsView = ({ handleDelete, user, handleEditUser }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const actions = [
    {
      icon: (
        <DateRangeTwoToneIcon
          style={{ color: "green" }}
          onClick={() => handleEditUser(true, user)}
        />
      ),
      name: "Editar",
    },
    {
      icon: (
        <DeleteForeverTwoToneIcon
          style={{ color: "red" }}
          onClick={handleDelete}
        />
      ),
      name: "Excluir",
    },
  ];

  return (
    <SpeedDial
      ariaLabel="Ações"
      classes={{ fab: classes.fab }}
      icon={<SettingsTwoToneIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="left"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={handleClose}
        />
      ))}
    </SpeedDial>
  );
};

export default ActionsView;

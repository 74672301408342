import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// MUI COMPONENTS
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
// DATEPICKER
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// COMPONENTS

// FIREBASE
import firebase from "../../../config/fbConfig";

function SimpleDialog(props) {
  const { edit } = props;

  const [date, changeDate] = useState(new Date());

  useEffect(() => {
    if (edit.user.dueDate !== undefined) changeDate(edit.user.dueDate);
  }, [edit.user.dueDate]);

  const handleSave = () => {
    // SAVE TO FIREBASE
    firebase
      .firestore()
      .collection("users")
      .doc(edit.user.userId)
      .set({ dueDate: date.toString() }, { merge: true });

    let userUpdated = { ...edit.user, dueDate: date };
    edit.handleEditUser(false, userUpdated);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        onClose={() => edit.handleEditUser(false, edit.user)}
        aria-labelledby="simple-dialog-title"
        open={edit.open}
        style={{ margin: "0px" }}
      >
        <Box>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
          />
        </Box>
        <Box display="flex" flexDirection="row-reverse">
          <Button onClick={() => handleSave()} color="primary">
            Agendar Bloqueio
          </Button>
          <Button
            onClick={() => edit.handleEditUser(false, edit.user)}
            color="secondary"
          >
            Fechar
          </Button>
        </Box>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo({ edit }) {
  return (
    <div>
      <SimpleDialog
        open={edit.open}
        user={edit.user}
        edit={edit}
        onClose={() => edit.handleEditUser(false)}
      />
    </div>
  );
}

import React, { useEffect, useRef } from "react";
// COMPONENTS
import Message from "../messages-components/Message";
import FabMenu from "../messages-components/Fab";
import LayoutsDefault from "../../layouts/Default";
import CommentsDialog from "../messages-components/CommentsDialog";
import CommentButton from "../messages-components/CommentButton";
// MUI
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Send from "@material-ui/icons/Send";
import { green } from "@material-ui/core/colors";

export default function Index(props) {
  const {
    messages,
    config,
    handleSetConfig,
    message: messageBody,
    handleChange,
    handleSave,
    showMenu,
    imageUrl,
    handleImageCancel,
    fetchMessages,
    scroll,
    handleHideMenu,

    handleOpenComments,
    openComments,
    messageComments,
    disableAutoScroll,
    setDisableAutoScroll,
  } = props;

  // scroll logic start
  const messagesElement = useRef();
  const [prevHeight, setPrevHeight] = React.useState("");
  // const [disableAutoScroll, setDisableAutoScroll] = React.useState("");

  useEffect(() => {
    scrollKeepPosition();
  }, [scroll]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (disableAutoScroll) return;
    messagesElement.current.scrollTop = messagesElement.current.scrollHeight;
  };

  const scrollKeepPosition = () => {
    messagesElement.current.scrollTop =
      messagesElement.current.scrollHeight - prevHeight;
  };

  // infinite scroll
  const onScroll = (event) => {
    console.log(prevHeight);
    if (event.target.scrollTop === 0) {
      // if user is scrolling dont interrupt him
      setDisableAutoScroll(true);
      setTimeout(() => setDisableAutoScroll(false), 5000);

      setPrevHeight(messagesElement.current.scrollHeight);
      fetchMessages();
    }
  };
  // scroll logic end
  return (
    <>
      <LayoutsDefault>
        <Box
          ref={messagesElement}
          display="flex"
          flexDirection="column"
          m={1}
          p={1}
          bgcolor="background.paper"
          style={{ overflowY: "scroll", height: "calc(100vh - 140px)" }}
          onScroll={onScroll}
        >
          <Box>
            {messages.length !== 0
              ? messages
                  .sort(function (a, b) {
                    if (a.createdAt < b.createdAt) {
                      return -1;
                    }
                    if (a.createdAt > b.createdAt) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((message, index) => (
                    <Message
                      key={index}
                      {...message}
                      setDisableAutoScroll={setDisableAutoScroll}
                      CommentButton={
                        <CommentButton
                          handleOpenComments={handleOpenComments}
                          message={message}
                        />
                      }
                    />
                  ))
              : ""}
          </Box>
          <Box
            display="flex"
            p={1}
            style={{
              width: "100%",
              boxSizing: "border-box",
              bottom: "0px",
              position: "fixed",
              left: "0",
              backgroundColor: "white",
            }}
          >
            <Divider />
            {imageUrl !== "" ? (
              <Box
                // hidden={true}
                style={{
                  position: "fixed",
                  width: "100%",
                  backgroundColor: "white",
                  left: "0px",
                  height: "calc(100% - 119px)",
                  bottom: "0px",
                  display: "flex",
                  top: "56px",
                  zIndex: "11",
                  overflow: "hidden",
                }}
              >
                <p
                  onClick={() => {
                    handleImageCancel();
                  }}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    color: "red",
                    position: "fixed",
                  }}
                >
                  Cancelar
                </p>
                <img
                  alt="this was posted"
                  style={{
                    margin: "auto",
                    display: "inline-block",
                    width: "50%",
                  }}
                  src={imageUrl}
                />
              </Box>
            ) : (
              ""
            )}
            <Box flexGrow={1} style={{ backgroundColor: "white", zIndex: "1" }}>
              <TextField
                multiline
                style={{ width: "100%", bottom: 0, boxSizing: "border-box" }}
                id="message"
                label="Notificação"
                value={messageBody}
                onChange={(event) => handleChange(event)}
              />
            </Box>
            <Box
              style={{ position: "relative", bottom: "-18px", width: "51px" }}
            >
              {showMenu !== true ? (
                <Fab
                  style={{
                    bottom: "20px",
                    left: "0px",
                    position: "absolute",
                    zIndex: 111,
                  }}
                  color="secondary"
                  aria-label="edit"
                  handleSetConfig={handleSetConfig}
                  config={config}
                  handleHideMenu={handleHideMenu}
                  onClick={() => handleSave(config)}
                >
                  <Send style={{ color: green[500] }} />
                </Fab>
              ) : (
                <FabMenu
                  handleSave={handleSave}
                  config={config}
                  handleSetConfig={handleSetConfig}
                  handleChange={handleChange}
                />
              )}
            </Box>
          </Box>
        </Box>
        <CommentsDialog
          open={openComments}
          handleOpen={handleOpenComments}
          messageComments={messageComments}
        />
      </LayoutsDefault>
    </>
  );
}

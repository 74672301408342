export const isUserBlocked = (dueDate) => {
  let currentDate = new Date();
  if (dueDate === "") {
    return true;
  }
  if (currentDate > new Date(dueDate)) {
    return true;
  } else {
    return false;
  }
};

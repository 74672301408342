import React, { Component } from "react";
// COMPONENTS
import EditDateView from "./editdate-view";

class EditDate extends Component {
  state = { date: "" };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    console.log("change");
  };
  handleSave = () => {
    console.log("save");
  };
  render() {
    return (
      <EditDateView
        {...this.state}
        handleChange={this.handleChange}
        handleSave={this.handleSave}
        edit={this.props.edit}
      />
    );
  }
}

export default EditDate;

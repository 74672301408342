import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  handleChange,
  handleSave,
  selector,
  open,
  setOpen,
  error,
  id,
  title,
  body,
  category,
  post,
}) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true, "");
  };

  const handleClickClose = () => {
    setOpen(false, "");
  };
  const handleClickSave = () => {
    handleSave();
  };

  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        style={{
          bottom: "20px",
          right: "22px",
          position: "absolute",
        }}
        color="secondary"
        aria-label="edit"
      >
        <AddIcon style={{ color: green[500] }}></AddIcon>
      </Fab>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClickClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClickClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Página
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClickSave}>
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        {/* PAGE CONTENT GOES HERE  */}
        <Container>
          <Box p={1} flexGrow={1}>
            <TextField
              error={error.title ? error : false}
              helperText={error.title}
              name="title"
              label="Título"
              style={{ width: "100%" }}
              onChange={handleChange}
              value={title}
            />
          </Box>
          {selector}
          {error.category ? (
            <Box p={1}>
              <Typography
                color="error"
                variant="caption"
                display="block"
                gutterBottom
              >
                {error.category}
              </Typography>
            </Box>
          ) : null}
          <TextareaAutosize
            name="body"
            label="HTML"
            multiline
            rowsMin={4}
            rowsMax={30}
            defaultValue={body}
            variant="outlined"
            onChange={handleChange}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {error.body ? (
            <Box p={1}>
              <Typography
                color="error"
                variant="caption"
                display="block"
                gutterBottom
              >
                {error.body}
              </Typography>
            </Box>
          ) : null}
        </Container>
      </Dialog>
    </div>
  );
}

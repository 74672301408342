import React, { Component } from "react";
// COMPONENTS
import SignupView from "./signup-view";
// FIREBASE
import firebase from "../../config/fbConfig";

class SignupContainer extends Component {
  state = {
    errors: {},
    newUserData: { email: "", password: "", confirmPassword: "", handle: "" },
  };
  handleChange = (event) => {
    this.setState({
      newUserData: {
        ...this.state.newUserData,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleValidation()) this.signupUser();
  };
  handleValidation = () => {
    const { password, confirmPassword, handle } = this.state.newUserData;
    this.setState({ errors: {} });
    if (handle === "") {
      this.setState({ errors: { handle: "o campo nome é obrigatório." } });
      return false;
    }
    if (password !== confirmPassword) {
      this.setState({ errors: { confirmPassword: "senha não confere" } });
      return false;
    }
    return true;
  };
  signupUser = () => {
    const { email, password, handle } = this.state.newUserData;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((cred) => {
        return firebase.firestore().collection("users").doc(cred.user.uid).set({
          handle: handle,
          dueDate: "",
          email: email,
          userId: cred.user.uid,
        });
      })
      .then(() => {
        this.props.history.push("/messages");
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(errorMessage, errorCode);
        if (errorCode === "auth/invalid-email")
          this.setState({ errors: { email: "e-email inválido" } });
      });
  };
  render() {
    return (
      <SignupView
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default SignupContainer;

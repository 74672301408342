import React from "react";
// COMPONENTS
import EditPost from "../posts-components/EditPost";
import PostCardAdmin from "../posts-components/PostCardAdmin";
import LayoutsDefault from "../../layouts/Default";

// MUI
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";

export default function Index({ posts, editPost, editOpen, handleEditPost }) {
  return (
    <>
      <LayoutsDefault>
        <Box
          display="flex"
          flexDirection="column"
          m={1}
          p={1}
          bgcolor="background.paper"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100vh - 140px)",
          }}
        >
          <Box>
            {posts.length !== 0
              ? posts
                  .sort(function (a, b) {
                    if (a.createdAt < b.createdAt) {
                      return -1;
                    }
                    if (a.createdAt > b.createdAt) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((post) => (
                    <PostCardAdmin
                      post={post}
                      handleEditPost={handleEditPost}
                    />
                  ))
              : ""}
          </Box>
          <Box
            display="flex"
            p={1}
            style={{
              width: "100%",
              boxSizing: "border-box",
              bottom: "0px",
              position: "fixed",
              left: "0",
              backgroundColor: "white",
            }}
          >
            <Divider />
          </Box>
        </Box>
        <FloatingButton handleEditPost={handleEditPost} />
        {editOpen ? (
          <EditPost
            editOpen={editOpen}
            edit={{
              open: editOpen,
              post: editPost,
              handleEditPost: handleEditPost,
            }}
          />
        ) : null}
      </LayoutsDefault>
    </>
  );
}
const FloatingButton = ({ handleEditPost }) => {
  return (
    <Fab
      onClick={() => handleEditPost(true, "")}
      style={{
        bottom: "20px",
        right: "22px",
        position: "absolute",
      }}
      color="secondary"
      aria-label="edit"
    >
      <AddIcon style={{ color: green[500] }}></AddIcon>
    </Fab>
  );
};

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";

// COMPONENTS
import Message from "../Message";
import Comments from "../CommentsDialog/components/Comments";
import CommentForm from "../CommentsDialog/components/CommentForm";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, messageComments } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [zoom, setZoom] = React.useState({
    open: false,
    image: "",
  });

  const handleZoom = (obj) => {
    setZoom(obj);
  };

  const commentsElement = useRef();
  const autoScroll = () => {
    commentsElement.current.scrollTop = commentsElement.current.scrollHeight;
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Comentar
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          overflowY: "scroll",
          marginTop: 64,
          height: "calc(100% - 128px)",
        }}
        ref={commentsElement}
      >
        <Message {...messageComments} handleZoom={handleZoom} />
        <Comments
          autoScroll={autoScroll}
          comments={props.comments}
          messageComments={props.messageComments}
        />
      </div>
      <CommentForm
        message={messageComments}
        autoScroll={autoScroll}
        commentsCount={props.comments.length}
      />
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const { open, handleOpen } = props;

  return (
    <div>
      <SimpleDialog
        open={open}
        handleOpen={handleOpen}
        onClose={() => handleOpen(false)}
        {...props}
      />
    </div>
  );
}

import React, { Component } from "react";
// COMPONENTS
import CommentsView from "./comments-view";
// API
import firebase from "../../../../../config/fbConfig";

class CommentsContainer extends Component {
  db = firebase.firestore();
  state = {};

  handleDelete = (commentId, message) => {
    let response = window.confirm("Atenção! Excluir comentário?");

    if (response) this.db.collection("comments").doc(commentId).delete();

    this.db
      .collection("messages")
      .doc(this.props.messageComments.id)

      .update({
        commentsCount: this.props.messageComments.commentsCount - 1,
      })
      .then((ref) => {
        // console.log(ref);
        // this.props.autoScroll();
      })
      .catch((err) => console.warn(err));
  };

  render() {
    return (
      <CommentsView
        comments={this.props.comments}
        handleDelete={this.handleDelete}
        // autoScroll={this.props.autoScroll}
      />
    );
  }
}

export default CommentsContainer;

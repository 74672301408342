import React, { Component } from "react";
// COMPONENTS
import LoginView from "./login-view";
import firebase from "../../config/fbConfig";

class LoginContainer extends Component {
  auth = firebase.auth();

  state = { login: { email: "", password: "" }, errors: {} };

  handleChange = (event) => {
    this.setState({
      login: { ...this.state.login, [event.target.name]: event.target.value },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.loginUser();
  };
  loginUser = () => {
    const { email, password } = this.state.login;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((cred) => {
        localStorage.setItem("userName", "DICASBET365 PREMIUM");
        console.log("signInWithEmailAndPassword");
        if (cred.user.email === "admin@admin.com") {
          this.props.history.push("/messages");
        } else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              console.log("user signed out");
            });
        }
      })
      .catch((error) => {
        let errorCode = error.code;

        if (errorCode === "auth/invalid-email")
          this.setState({ errors: { email: "e-email inválido" } });

        if (errorCode === "auth/wrong-password")
          this.setState({ errors: { password: "senha inválida" } });
      });
  };

  render() {
    return (
      <LoginView
        {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default LoginContainer;

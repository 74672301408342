import React, { Component } from "react";
// API
import firebase from "../../../config/fbConfig";

// COMPONENTS
import View from "./message-view";

class Container extends Component {
  db = firebase.firestore();
  state = {};
  handleDelete = () => {
    let response = window.confirm("Atenção! Excluir mensagem?");

    if (response) {
      this.db.collection("messages").doc(this.props.id).delete();
      this.props.setDisableAutoScroll(true);
    }
  };
  render() {
    return (
      <>
        <View handleDelete={this.handleDelete} {...this.props} />
      </>
    );
  }
}

export default Container;

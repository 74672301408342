import React from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import AddCircleTwoToneIcon from "@material-ui/icons/AddCircleTwoTone";
import SaveTwoToneIcon from "@material-ui/icons/SaveTwoTone";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

const CategorySelector = ({
  handleChange,
  handleClickSave,
  handleClickAdd,
  handleClickDelete,
  handleClickCancel,
  categories,
  newCategory,
  selectedCategory,
  addMode
}) => {
  const conditionalButtonDelete = () =>
    selectedCategory !== null && addMode === false ? (
      <IconButton onClick={handleClickDelete} aria-label="delete">
        <DeleteForeverTwoToneIcon style={{ color: "red" }} />
      </IconButton>
    ) : (
      ""
    );
  const conditionalButtonAdd = () =>
    addMode === false ? (
      <IconButton onClick={handleClickAdd} aria-label="add">
        <AddCircleTwoToneIcon style={{ color: "green" }} />
      </IconButton>
    ) : (
      <>
        <Box display="flex" p={1}>
          <IconButton onClick={handleClickSave} aria-label="save">
            <SaveTwoToneIcon style={{ color: "green" }} />
          </IconButton>
          <IconButton onClick={handleClickCancel} aria-label="cancel">
            <CancelTwoToneIcon style={{ color: "gray" }} />
          </IconButton>
        </Box>
      </>
    );
  const conditionalInput = () =>
    addMode === false ? (
      <Select
        labelId="selectedCategory-label"
        name="selectedCategory"
        style={{ width: "100%" }}
        value={selectedCategory !== undefined ? selectedCategory : ""}
        onChange={handleChange}
      >
        {categories.size > 0 ? (
          Array.from(categories, ([key, value]) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={selectedCategory}>
            {categories.get(selectedCategory)}
          </MenuItem>
        )}
      </Select>
    ) : (
      <TextField
        style={{ width: "100%" }}
        name="newCategory"
        label="Adicionar Categoria"
        onChange={handleChange}
        value={newCategory}
      />
    );
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      bgcolor="background.paper"
    >
      <Box p={1} display="flex" flexGrow={1}>
        {conditionalInput()}
      </Box>
      <Box p={1}>
        {conditionalButtonDelete()}
        {conditionalButtonAdd()}
      </Box>
    </Box>
  );
};
export default CategorySelector;

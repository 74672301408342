import React, { useState } from "react";
// COMPONENTS
import LayoutsDefault from "../../layouts/Default";
import Dialog from "../configuration-components/Dialog";
// MUI ICONS
import TitleTwoToneIcon from "@material-ui/icons/TitleTwoTone";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
//  MUI
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// FIREBASE
import firebase from "../../config/fbConfig";

const Configuration = () => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [value, setValue] = React.useState("");
  const [config, setConfig] = useState({
    barText: {
      id: "barText",
      label: "Texto da barra superior",
      value: "... membros premium",
    },
    adminName: {
      id: "adminName",
      label: "Nome do administrador",
      value: "DICASBET365 PREMIUM",
    },
  });

  React.useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = () => {
    // real-time listener
    firebase
      .firestore()
      .collection("appConfig")
      .doc("config1")
      .onSnapshot((snapshot) => {
        setConfig({
          ...config,
          ...snapshot.data(),
        });
      });
  };

  const handleEdit = (property) => {
    setId(property.id);
    setLabel(property.label);
    setValue(property.value);
    setOpen(true);
  };

  const handleChange = (e) => {
    setConfig({
      ...config,
      [e.target.id]: { ...config[e.target.id], value: e.target.value },
    });

    setValue(e.target.value);
  };
  const handleSave = () => {
    firebase
      .firestore()
      .collection("appConfig")
      .doc("config1")
      .set({ ...config }, { merge: true });
    setOpen(false);
  };
  return (
    <LayoutsDefault>
      <div style={{ display: "flex", padding: 8 }}>
        <div>
          <TitleTwoToneIcon style={{ height: 50, width: "auto", padding: 2 }} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant="body1" align="left">
            {config.adminName.label}
          </Typography>
          <Typography
            align="left"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              maxWidth: "176px",
            }}
            variant="h6"
          >
            {config.adminName.value}
          </Typography>
        </div>
        <div>
          <IconButton
            aria-label="edit"
            onClick={() => handleEdit(config.adminName)}
          >
            <EditTwoToneIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
      {/*  */}

      <div style={{ display: "flex", padding: 8 }}>
        <div>
          <TitleTwoToneIcon style={{ height: 50, width: "auto", padding: 2 }} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant="body1" align="left">
            Texto da barra superior
          </Typography>
          <Typography
            align="left"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              maxWidth: "176px",
            }}
            variant="h6"
          >
            {config.barText.value}
          </Typography>
        </div>
        <div>
          <IconButton
            aria-label="edit"
            onClick={() => handleEdit(config.barText)}
          >
            <EditTwoToneIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
      <Dialog
        open={open}
        setOpen={setOpen}
        id={id}
        label={label}
        value={value}
        handleChange={handleChange}
        handleSave={handleSave}
      />
    </LayoutsDefault>
  );
};

export default Configuration;

import React from "react";
// COMPONETNS
import LayoutsLogin from "../../layouts/Login";

// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
// STYLE
import classes from "./login.module.css";
// IMAGES
import appIcon from "../../images/logo512.png";

const LoginView = ({ handleSubmit, handleChange, errors, login, loading }) => {
  return (
    <LayoutsLogin>
      <Grid container>
        <Grid item sm xs />
        <Grid item sm xs={10}>
          <Paper elevation={3} className={classes.paper}>
            <Avatar className={classes.avatar} src={appIcon} />
            <Typography className={classes.pageTitle} variant="h4">
              Entrar - Admin
            </Typography>
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                className={classes.textField}
                helperText={errors.email}
                error={errors.email ? true : false}
                value={login.email}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                type="password"
                label="Password"
                className={classes.textField}
                helperText={errors.password}
                error={errors.password ? true : false}
                value={login.password}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                Entrar
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
              <br />
            </form>
          </Paper>
        </Grid>
        <Grid item sm xs />
      </Grid>
    </LayoutsLogin>
  );
};

export default LoginView;

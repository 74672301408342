import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";

const View = ({ message, handleChange, handleSubmit }) => {
  return (
    <Box
      style={{
        display: "flex",
        padding: "12px",
        width: "100%",
        bottom: "0px",
        position: "fixed",
        background: "white",
      }}
    >
      <Box flexGrow={1} style={{ backgroundColor: "white", zIndex: "1" }}>
        <TextField
          multiline
          style={{ width: "100%", bottom: 0, boxSizing: "border-box" }}
          id="message"
          label="Digite aqui seu comentário"
          value={message}
          onChange={handleChange}
        />
      </Box>
      <Box style={{ position: "relative", bottom: "-8px", width: "51px" }}>
        <IconButton aria-label="delete" onClick={handleSubmit}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default View;

import React, { Component } from "react";
// COMPONENTS
// import UsersView from "./users-view";
import UsersView from "./users2-view";
// FIREBASE
import firebase from "../../config/fbConfig";
// SERVICES
import * as fb from "../../services/firebase";

import { isUserBlocked } from "../../utils/functions";
import KeyboardDateInput from "@material-ui/pickers/_shared/KeyboardDateInput";

class UsersContainer extends Component {
  state = {
    users: [],
    user: { handle: "", email: "", dueDate: "" },
    open: false,
  };

  db = firebase.firestore();

  fetchUsersNext = () => {
    const quantity = 20;
    const fetchRef = firebase
      .firestore()
      .collection("users")
      .orderBy("dueDate", "asc")
      .limit(quantity)
      .startAfter(this.lastVisible);

    fetchRef.get().then((documentSnapshots) => {
      documentSnapshots.docs.map((doc) => {
        let newUser = { ...doc.data(), id: doc.id };
        this.setState({
          users: [...this.state.users, newUser],
          scroll: Math.random(),
        });
      });
      this.lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
    });
  };

  fetchUsers = () => {
    // fetch users
    let users = [];
    const quantity = 20;
    const fetchRef = firebase
      .firestore()
      .collection("users")
      // .orderBy("dueDate", "desc")
      .orderBy("dueDate", "asc")
      .limit(quantity);

    fetchRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          let newUser = { ...change.doc.data(), id: change.doc.id };
          if (newUser.email !== "admin@admin.com") users.push(newUser);
          this.setState({
            users: [...this.state.users, newUser],
          });
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }
        if (change.type === "removed") {
          let users = this.state.users;
          let index = users.findIndex((element) => {
            if (element.id === change.doc.id) {
              return true;
            }
            return false;
          });
          users.splice(index, 1);
          this.setState({ users: users });
        }

        if (change.type === "modified") {
          let newUser = { ...change.doc.data(), id: change.doc.id };
          users = users.filter((user) => user.id !== newUser.id);
          users.push(newUser);

          // delete token
          if (isUserBlocked(newUser.dueDate)) {
            firebase
              .firestore()
              .collection("notificationGroup")
              .doc("group1")
              .update({
                [newUser.id]: firebase.firestore.FieldValue.delete(),
              });
          }
          this.setState({ users: users });
        }
      });
    });
  };
  handleEditUser = (open, user) => {
    this.setState({ open: open, user: user });
  };

  handlePagination = (letter) => {
    if (letter === "#") {
      fb.readPaginatedNumbers("users", "handle").then((data) => {
        this.setState({ users: data });
      });
    } else {
      let usersList1 = {};
      let usersList2 = {};
      fb.readPaginatedCustom("users", "handle", letter, 1000).then(
        (firstQuery) => {
          firstQuery.map((user) => {
            usersList1[user.id] = user;
          });
          fb.readPaginatedCustom("users", "email", letter, 1000).then(
            (secondQuery) => {
              secondQuery.map((user) => {
                usersList2[user.id] = user;
              });
              const usersObj = Object.assign(usersList1, usersList2);
              let usersArr = [];
              Object.keys(usersObj).map((userKey) => {
                usersArr.push(usersObj[userKey]);
              });
              this.setState({ users: usersArr });
            }
          );
        }
      );
    }
  };

  componentDidMount() {
    // this.fetchUsers();
    this.handlePagination("A");
  }

  render() {
    const { users } = this.state;

    return (
      <UsersView
        open={this.state.open}
        handleEditUser={this.handleEditUser}
        onPaginationClick={this.handlePagination}
        users={users}
        fetchUsers={this.fetchUsersNext}
        edit={{
          open: this.state.open,
          user: this.state.user,
          handleEditUser: this.handleEditUser,
        }}
      />
    );
  }
}

export default UsersContainer;

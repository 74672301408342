import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// COMPONENTS
import Actions from "../postcardadmin-components/Actions";

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
    backgroundColor: "#82ffa04f",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({
  post,
  id,
  title,
  body,
  category,
  createdAt,
  handleEditPost,
}) {
  const classes = useStyles();
  const date = new Date(post.createdAt);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (parseInt(date.getMonth()) + 1)).slice(-2);
  const year = date.getFullYear();

  return (
    <>
      <Paper className={classes.root}>
        <div
          style={{
            float: "right",
            position: "relative",
            right: "15px",
            top: "-8px",
            width: "10px",
          }}
        >
          <Actions id={id} post={post} handleEditPost={handleEditPost} />
        </div>
        <Typography variant="button">{post.title}</Typography>
        <Typography
          display="block"
          variant="caption"
          className={classes.pos}
          color="textSecondary"
        >
          {`Criado em: ${day}/${month}/${year}`}
        </Typography>
      </Paper>
    </>
  );
}

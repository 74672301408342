import React, { useState, useEffect } from "react";
import "./App.css";
// COMPONENTS
import PagesMessages from "./pages/Messages";
import PagesPosts from "./pages/Posts";
import PagesUsers from "./pages/Users";
import PagesConfiguration from "./pages/Configuration";
import PagesLogin from "./pages/Login";
import PagesSignup from "./pages/Signup";
// REACT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// MUI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { teal, red } from "@material-ui/core/colors";
// FIREBASE
import firebase from "./config/fbConfig";

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: red,
  },
});

function App() {
  const [login, setLogin] = useState(null);

  useEffect(() => {
    fetchConfig();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("login", true);
        //  localStorage.setItem("userName", "DICASBET365 PREMIUM");

        setLogin(true);
      } else {
        localStorage.setItem("login", false);
        setLogin(false);
      }
    });
  });

  const fetchConfig = () => {
    // real-time listener
    firebase
      .firestore()
      .collection("appConfig")
      .doc("config1")
      .onSnapshot((snapshot) => {
        localStorage.setItem("userName", snapshot.data().adminName.value);
      });
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          {localStorage.getItem("login") === "true" ? null : (
            <Redirect to={{ pathname: "/login" }} />
          )}
          <Switch>
            <Route
              path="/login"
              component={
                localStorage.getItem("login") === "true"
                  ? PagesMessages
                  : PagesLogin
              }
            />
            <Route
              path="/signup"
              component={
                localStorage.getItem("login") === "true"
                  ? PagesMessages
                  : PagesSignup
              }
            />
            <Route path="/messages" component={PagesMessages} />
            <Route path="/posts" component={PagesPosts} />
            <Route path="/users" component={PagesUsers} />
            <Route path="/configuration" component={PagesConfiguration} />
            <Route path="/" component={PagesMessages} />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

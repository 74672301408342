import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import TitleTwoToneIcon from "@material-ui/icons/TitleTwoTone";
// COMPONENTS
import Switch from "../Switch";
import IconAndText from "../IconAndText";
// FIREBASE
import firebase from "../../../config/fbConfig";

const db = firebase.firestore();

function SimpleDialog(props) {
  const { onClose, open, config, setConfig } = props;

  const handleClose = () => {
    handleSave();
    onClose(false);
  };
  const handleSave = () => {
    // Add a new document with a generated id.
    db.collection("messagesConfig")
      .doc("config1")
      .set({
        messageTitle: config.messageTitle,
        messageIcon: config.messageIcon,
        statusbarIcon: config.statusbarIcon,
        thumbnail: config.thumbnail,
        customTitle: config.customTitle
      })
      .then(ref => {
        console.log("Added document with ID: ");
      });
  };

  const handleChangeCheckBox = (name, event) => {
    setConfig({ ...config, [name]: event.target.checked });
  };
  const handleChange = (name, event) => {
    setConfig({ ...config, [name]: event.target.value });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Push Notification</DialogTitle>

      <Divider />
      <MuiDialogContent dividers>
        <Switch
          label="Thumbnail"
          name="thumbnail"
          checked={config.thumbnail}
          handleChange={handleChangeCheckBox}
        />
        <Switch
          label="Título Customizado"
          name="customTitle"
          checked={config.customTitle}
          handleChange={handleChangeCheckBox}
        />
        {config.customTitle ? (
          <div>
            <IconAndText
              handleChange={event => handleChange("messageTitle", event)}
              value={config.messageTitle}
              icon={<TitleTwoToneIcon />}
              label="Título"
            />
          </div>
        ) : (
          ""
        )}

        <div>
          <IconAndText
            handleChange={event => handleChange("messageIcon", event)}
            value={config.messageIcon}
            icon={<SentimentVerySatisfiedTwoToneIcon />}
            label="Thumbnail (URL)"
          />
        </div>
        <div>
          <IconAndText
            handleChange={event => handleChange("statusbarIcon", event)}
            value={config.statusbarIcon}
            icon={<NotificationsActiveTwoToneIcon />}
            label="Notificação (URL)"
          />
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Salvar
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default function SimpleDialogDemo({
  openNotification,
  handleCloseOptions,
  config,
  setConfig
}) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openNotification);
  }, [openNotification]);

  return (
    <div>
      <SimpleDialog
        open={open}
        onClose={handleCloseOptions}
        config={config}
        setConfig={setConfig}
      />
    </div>
  );
}

import React from "react";
// MUI
import Container from "@material-ui/core/Container";
// COMPONENTS
import AppBar from "../AppBar";

export default function Index(props) {
  return (
    <div className="default-layout">
      <AppBar />
      <div className="body">
        <Container style={{ height: "100vh", paddingTop: 72 }} fixed>
          {props.children}
        </Container>
      </div>
    </div>
  );
}

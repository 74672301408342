import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
// MUI
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// MUI ICONS
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import SearchIcon from "@material-ui/icons/Search";
// COMPONENTS
import LayoutsFullWidth from "../../layouts/FullWidth";
import UserCardAdmin from "../users-components/UserCardAdmin";
import EditDate from "../users-components/EditDate/editdate-container";
import Pagination from "./PaginationLetter";
// UTILS
import { isUserBlocked } from "../../utils/functions";
// SERVICES
import * as fb from "../../services/firebase";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FullWidthTabs(props) {
  const { users, edit, fetchUsers, scroll } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [term, setTerm] = React.useState("");

  const handleChangeSearch = (e) => {
    setTerm(e.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const unlockedUsers = () => {
    return users
      .filter((user) => !isUserBlocked(user.dueDate))
      .map((user, index) => (
        <UserCardAdmin
          key={index}
          handleEditUser={edit.handleEditUser}
          user={user}
        />
      ));
  };
  const lockedUsers = () => {
    return users
      .filter((user) => isUserBlocked(user.dueDate))
      .map((user, index) => (
        <UserCardAdmin
          key={index}
          handleEditUser={edit.handleEditUser}
          user={user}
        />
      ));
  };

  const searchingFor = (term) => {
    return function (user) {
      return (
        user.handle.toLowerCase().includes(term.toLowerCase()) ||
        user.email.toLowerCase().includes(term.toLowerCase()) ||
        !term
      );
    };
  };
  const searchedUser = () => {
    return (
      <div>
        {users.filter(searchingFor(term)).map((user, index) => {
          return (
            <UserCardAdmin
              key={index}
              handleEditUser={edit.handleEditUser}
              user={user}
            />
          );
        })}
      </div>
    );
  };

  // scroll logic start
  const usersElement = useRef();
  const [prevHeight, setPrevHeight] = React.useState("");
  const [disableAutoscroll, setDisableAutoScroll] = React.useState(false);

  useEffect(() => {
    scrollKeepPosition();
  }, [scroll]);
  useEffect(() => {
    scrollToBottom();
  }, [users]);

  const scrollToBottom = () => {
    if (disableAutoscroll) return;
    if (usersElement.current)
      usersElement.current.scrollTop = usersElement.current.scrollHeight;
  };

  const scrollKeepPosition = () => {
    if (usersElement.current)
      usersElement.current.scrollTop =
        usersElement.current.scrollHeight - prevHeight;
  };

  // infinite scroll
  const onScroll = (event) => {
    const height = usersElement.current.scrollHeight;
    const elementHeight = usersElement.current.clientHeight;
    const scrollTrigger = height - elementHeight;

    if (
      event.target.scrollTop == scrollTrigger &&
      disableAutoscroll === false
    ) {
      // if user is scrolling dont interrupt him
      setDisableAutoScroll(true);
      setTimeout(() => setDisableAutoScroll(false), 5000);

      setPrevHeight(usersElement.current.scrollHeight);
      fetchUsers();
    }
  };
  // scroll logic end
  const [data, setData] = React.useState([]);
  const handlePagination = (letter) => {
    fb.readPaginatedCustom("users", 2, "handle", letter).then((data) => {
      setData(data);
    });
  };

  const postData = data.map((item) => (
    <div>
      <p>{item.name}</p>
    </div>
  ));

  return (
    <div className={classes.root}>
      <LayoutsFullWidth>
        <EditDate edit={edit} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: "100%" }}
        >
          <Box
            flexGrow={1}
            style={{
              overflow: "auto",
            }}
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div
                  ref={usersElement}
                  onScroll={onScroll}
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {unlockedUsers()}
                </div>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                {lockedUsers()}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <TextField
                  onChange={handleChangeSearch}
                  id="standard-basic"
                  label="Busca"
                  style={{ marginBottom: "20px" }}
                />
                {searchedUser()}
              </TabPanel>
            </SwipeableViews>
          </Box>
          <Box flexShrink={0}>
            <Pagination onClick={props.onPaginationClick} />
          </Box>
          <Box flexShrink={0}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  icon={<LockOpenTwoToneIcon />}
                  label="Desbloqueado"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<LockTwoToneIcon />}
                  label="Bloqueado"
                  {...a11yProps(1)}
                />
                <Tab icon={<SearchIcon />} label="Busca" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
          </Box>
        </Box>
      </LayoutsFullWidth>
    </div>
  );
}

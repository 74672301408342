import React, { Component } from "react";
import ActionsView from "./actions-view";

import firebase from "../../../../config/fbConfig";

class ActionsContainer extends Component {
  db = firebase.firestore();
  state = {};
  handleDelete = () => {
    if (!window.confirm("Atenção! Deseja excluir o usuário?")) return;
    this.db.collection("users").doc(this.props.user.userId).delete();
  };
  render() {
    return (
      <ActionsView
        handleDelete={this.handleDelete}
        handleEditUser={this.props.handleEditUser}
        user={this.props.user}
      />
    );
  }
}

export default ActionsContainer;

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhXAs7qS4iPB5upZxoVfq9bbgFnadCF4w",
  authDomain: "dicasbet365-83d0c.firebaseapp.com",
  databaseURL: "https://dicasbet365-83d0c.firebaseio.com",
  projectId: "dicasbet365-83d0c",
  storageBucket: "dicasbet365-83d0c.appspot.com",
  messagingSenderId: "970743260438",
  appId: "1:970743260438:web:cc0853a3ba75c7a7881c37",
  measurementId: "G-QZSRM430GK",
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});

export default firebase;

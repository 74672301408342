import React, { Component } from "react";
// COMPONENTS
import EditPostView from "./editpost-view";

import firebase from "../../../config/fbConfig";

class EditPostContainer extends Component {
  db = firebase.firestore();

  state = {
    title: "",
    category: this.props.selected,
    body: "",
    open: this.props.edit.open,
    id: "",
    post: {},
    error: {},
  };

  componentDidUpdate() {
    if (this.state.id !== this.props.edit.post.id && this.state.id === "") {
      this.fetchPost(this.props.edit.post.id);
    }
  }

  fetchPost = (id) => {
    if (id === null || id === undefined) return;
    let postRef = this.db.collection("posts").doc(id);

    postRef.get().then((doc) => {
      this.setState({ ...doc.data(), id: doc.id });
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleValidation = (input) => {
    if (input.title === "") {
      this.setState({ error: { title: "O campo título é obrigatório." } });
      return false;
    }
    if (input.category === null) {
      this.setState({
        error: { category: "O campo categoria é obrigatório." },
      });
      return false;
    }
    if (input.body === "") {
      this.setState({
        error: { body: "O campo da mensagem é obrigatório." },
      });
      return false;
    }
    return true;
  };
  handleSave = () => {
    if (!this.handleValidation(this.state)) return;
    let collection = this.db.collection("posts");

    let data = {
      title: this.state.title,
      category: this.state.category,
      categoryName: this.props.selectedName,
      body: this.state.body,
    };

    if (this.state.id === "") {
      data.createdAt = new Date().toISOString();
      collection.add(data).then((ref) => {
        console.log("Added document with ID: ", ref.id);
      });
    } else {
      data.createdAt = this.state.createdAt;
      collection.doc(this.state.id).set(data);
    }

    this.setState({ id: "", title: "", body: "", category: "" });
    this.props.edit.handleEditPost(false, "");
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = null;

    if (nextProps.selected !== prevState.selected)
      nextState = { category: nextProps.selected };
    if (nextProps.edit.open !== prevState.open);

    nextState = {
      ...nextState,
      open: nextProps.edit.open,
    };

    return nextState;
  }
  setOpen = (open, id) => {
    this.props.edit.handleEditPost(open, id);
    this.setState({ id: "", title: "", body: "", category: "" });
  };

  render() {
    return (
      <EditPostView
        selected={this.props.selected}
        selector={this.props.selector}
        handleChange={this.handleChange}
        handleSave={this.handleSave}
        setOpen={this.setOpen}
        {...this.state}
      />
    );
  }
}

export default EditPostContainer;

import React, { Component } from "react";
// COMPONENTS
import CategorySelectorView from "./categoryselector-view";

import firebase from "../../../../../config/fbConfig";

class CategorySelectorContainer extends Component {
  db = firebase.firestore();
  state = {
    selectedCategory: this.props.category,
    addMode: false,
    categories: new Map(),
    newCategory: "",
  };

  componentDidMount() {
    this.fetchCategories();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDelete = (selectedCategory) => {
    this.db.collection("categories").doc(selectedCategory).delete();
  };
  handleClickAdd = () => {
    this.setState({ addMode: !this.state.addMode });
  };
  handleClickDelete = () => {
    this.setState({ addMode: false });
    const { selectedCategory, categories } = this.state;
    categories.delete(selectedCategory);
    this.setState({
      categories: categories,
      selectedCategory: this.props.category,
    });
    this.handleDelete(selectedCategory);
  };

  fetchCategories() {
    // real-time listener
    let categories = new Map();
    this.db
      .collection("categories")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("added");
            categories.set(change.doc.id, change.doc.data().name);
          }
          if (change.type === "removed") {
            console.log(change.doc.id);
          }
        });
        this.setState({ categories: categories });
      });
  }
  handleClickSave = () => {
    if (this.state.newCategory !== "") {
      this.setState({
        addMode: false,
        selectedCategory: this.state.categories.length,
        newCategory: "",
      });
      this.handleSave();
    }
  };
  handleSave = () => {
    // Add a new document with a generated id.
    this.db
      .collection("categories")
      .add({
        name: this.state.newCategory,
        createdAt: new Date().toISOString(),
      })
      .then((ref) => {
        console.log("Added document with ID: ", ref.id);
        this.setState({
          title: "",
          open: false,
          selectedCategory: ref.id,
        });
      });
  };
  handleClickCancel = () => {
    this.setState({ addMode: false });
  };

  render() {
    const { selectedCategory, categories } = this.state;
    const categoryId = selectedCategory;
    const categoryName = categories.get(categoryId);

    return (
      <div>
        {this.props.render(
          categoryId,
          categoryName,
          <CategorySelectorView
            handleChange={this.handleChange}
            handleClickAdd={this.handleClickAdd}
            handleClickSave={this.handleClickSave}
            handleClickDelete={this.handleClickDelete}
            handleClickCancel={this.handleClickCancel}
            {...this.state}
          />
        )}
      </div>
    );
  }
}

export default CategorySelectorContainer;

import React, { Component } from "react";
import ActionsView from "./actions-view";

import firebase from "../../../../config/fbConfig";

class ActionsContainer extends Component {
  db = firebase.firestore();
  state = {};
  handleDelete = () => {
    this.db
      .collection("posts")
      .doc(this.props.post.id)
      .delete();
  };
  render() {
    return (
      <ActionsView
        handleDelete={this.handleDelete}
        handleEditPost={this.props.handleEditPost}
        post={this.props.post}
      />
    );
  }
}

export default ActionsContainer;

import React from "react";
import { Link } from "react-router-dom";

// COMPONETNS
import LayoutsLogin from "../../layouts/Login";
// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";

// STYLES
import classes from "./signup.module.css";
// IMAGES
import appIcon from "../../images/logo512.png";

const SignupView = ({
  newUserData,
  errors,
  handleChange,
  handleSubmit,
  loading,
}) => {
  return (
    <LayoutsLogin>
      <Grid container className={classes.form}>
        <Grid item sm xs />
        <Grid item sm xs={10}>
          <Paper className={classes.paper} raised>
            <Avatar src={appIcon} className={classes.avatar} />

            <Typography variant="h4" className={classes.pageTitle}>
              Sign Up
            </Typography>

            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                className={classes.textField}
                helperText={errors.email}
                error={errors.email ? true : false}
                value={newUserData.email}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                type="password"
                label="Password"
                className={classes.textField}
                helperText={errors.password}
                error={errors.password ? true : false}
                value={newUserData.password}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                className={classes.textField}
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={newUserData.confirmPassword}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                id="handle"
                name="handle"
                type="text"
                label="Nome de Usuario"
                className={classes.textField}
                helperText={errors.handle}
                error={errors.handle ? true : false}
                value={newUserData.handle}
                onChange={handleChange}
                fullWidth
              />
              {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                Sign Up
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
              <br />
              <small>
                Já possui seu cadastro? Entre <Link to="/login">aqui</Link>
              </small>
            </form>
          </Paper>
        </Grid>
        <Grid item sm xs />
      </Grid>
    </LayoutsLogin>
  );
};

export default SignupView;

import React, { Component } from "react";
// COMPONENTS
import MessagesView from "./messages-view";
// FIREBASE
import firebase from "../../config/fbConfig.js";
import "firebase/storage";

class MessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.lastVisible = {};
  }

  state = {
    messages: [],
    message: "",
    config: {},
    showMenu: true,
    imageInput: {},
    imageUrl: "",
    scroll: false,
    openComments: false,
    messageComments: {},
    disableAutoScroll: false,
    documentSnapshots: [],
  };

  db = firebase.firestore();

  componentDidMount() {
    this.fetchMessages();
    // this.fetchMessagesNext();
    this.fetchMessageConfig();
  }

  handleOpenComments = (open, message) => {
    this.setState({ openComments: open, messageComments: message });
  };
  setDisableAutoScroll = () => {
    this.setState({ disableAutoScroll: true });
  };

  // fetchMessages() {
  //   // real-time listener
  //   const quantity = 10;

  //   const fetchRef = this.db
  //     .collection("messages")
  //     .orderBy("createdAt", "desc")
  //     .limit(quantity);

  //   fetchRef.onSnapshot((documentSnapshots) => {
  //     let messages = this.manageChanges(documentSnapshots);
  //     this.setState({ messages: messages });
  //   });
  // }

  // fetchMessagesNext() {
  //   const quantity = 4;
  //   const fetchRef = this.db
  //     .collection("messages")
  //     .orderBy("createdAt", "desc")
  //     .limit(quantity)
  //     .startAfter(this.lastVisible);

  //   fetchRef.onSnapshot((documentSnapshots) => {
  //     let messages = this.manageChanges(documentSnapshots);

  //     this.setState({
  //       messages: messages,
  //       scroll: Math.random(),
  //     });
  //   });
  // }

  fetchMessages() {
    // real-time listener
    const quantity = 10;

    const fetchRef = this.db
      .collection("messages")
      .orderBy("createdAt", "desc")
      .limit(quantity);

    fetchRef.onSnapshot((documentSnapshots) => {
      documentSnapshots.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("added");
          let newMessage = { ...change.doc.data(), id: change.doc.id };
          this.setState({
            messages: [...this.state.messages, newMessage],
          });
          this.lastVisible =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];
        }
        if (change.type === "modified") {
          this.setState({
            messages: this.state.messages.map((message) => {
              if (message.id !== change.doc.id) {
                return message;
              } else {
                return { ...change.doc.data(), id: change.doc.id };
              }
            }),
          });
        }

        if (change.type === "removed") {
          this.setState({
            messages: this.state.messages.filter(
              (message) => message.id !== change.doc.id
            ),
          });
        }
      });
    });
  }

  fetchMessagesNext() {
    const quantity = 10;
    const fetchRef = this.db
      .collection("messages")
      .orderBy("createdAt", "desc")
      .limit(quantity)
      .startAfter(this.lastVisible);

    fetchRef.get().then((documentSnapshots) => {
      documentSnapshots.docs.map((doc) => {
        let newMessage = { ...doc.data(), id: doc.id };
        this.setState({
          messages: [...this.state.messages, newMessage],
          scroll: Math.random(),
        });
      });
      this.lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
    });
  }

  manageChanges = (documentSnapshots) => {
    let messages = this.state.messages;
    let itemRemoved =
      documentSnapshots
        .docChanges()
        .filter((change) => change.type === "removed").length > 0;

    documentSnapshots.docChanges().forEach((change) => {
      if (!itemRemoved && change.type === "added") {
        let newMessage = { ...change.doc.data(), id: change.doc.id };

        let itemExists =
          messages.filter((message) => message.id === newMessage.id).length > 0;

        if (!itemExists) messages = [...messages, newMessage];
      }
      if (change.type === "modified") {
        messages = messages.map((message) => {
          if (message.id !== change.doc.id) {
            return message;
          } else {
            return { ...change.doc.data(), id: change.doc.id };
          }
        });
      }

      if (change.type === "removed") {
        messages = messages.filter((message) => message.id !== change.doc.id);
        return;
        // teste
      }

      this.lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
    });
    return messages;
  };

  fixDatabase(message) {
    this.db
      .collection("messages")
      .doc(message.id)
      .update({
        updatedAt: message.createdAt,
      })
      .then((ref) => {
        // console.log(ref);
        // this.props.autoScroll();
      })
      .catch((err) => console.warn(err));
  }

  fetchMessageConfig() {
    let configRef = this.db.collection("messagesConfig").doc("config1");
    configRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          this.setState({ config: { ...doc.data() } });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  }

  handleSetConfig = (config) => {
    this.setState({ config: { ...config } });
  };

  handleChange = (event) => {
    if (event.target.id === "imageInput") {
      this.setState({
        imageUrl: URL.createObjectURL(event.target.value),
        showMenu: false,
        [event.target.id]: event.target.value,
      });
    }

    if (event.target.id === "message") {
      let showMenux = event.target.value.length === 0 ? true : false;

      this.setState({
        showMenu: showMenux,
        [event.target.id]: event.target.value,
      });
    }
  };

  handleHideMenu = () => {
    this.setState({ showMenu: false });
  };

  handleImageUpload = () => {
    let storageRef = firebase.storage().ref();
    let imageRef = storageRef.child(this.state.imageInput.name);
    var blob = new Blob([this.state.imageInput], { type: "image/jpeg" });

    imageRef
      .put(blob)
      .then((snapshot) => {
        this.getUrl(snapshot.metadata.name);
        return;
      })

      .catch((err) => console.log("error", err));
  };

  getUrl = (fileName) => {
    return firebase
      .storage()
      .ref(fileName)
      .getDownloadURL()
      .then((url) => url);
  };

  handleImageCancel = () => {
    this.setState({ imageUrl: "", imageInput: {}, showMenu: true });
  };
  handleSave = (config) => {
    const token = "1232648959:AAE29HwUI1AOinhVCgwDHqirV8pBCLoTjWY";
    const chatId = "-486075589";
    const body = this.state.message;

    // desenvolvimento integração telegram.
    // fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${body}`);
    // return;

    let storageRef = firebase.storage().ref();

    if (this.state.imageInput.name !== undefined) {
      let imageRef = storageRef.child(this.state.imageInput.name);
      var blob = new Blob([this.state.imageInput], { type: "image/jpeg" });

      // --
      this.setState({
        showMenu: true,
      });
      this.handleImageCancel();
      //  --
      imageRef
        .put(blob)
        .then((snapshot) => {
          return firebase
            .storage()
            .ref(snapshot.metadata.name)
            .getDownloadURL()
            .then((url) => {
              return url;
            });
        })
        .then((url) => {
          this.addNewDocument(config, url);
          this.setState({
            message: "",
            // showMenu: true,
          });
        });
    } else {
      this.addNewDocument(config, "");
      this.setState({
        message: "",
        showMenu: true,
      });
    }
  };

  addNewDocument = (config, imageurl) => {
    // // Add a new document with a generated id.
    // const token = '1232648959:AAE29HwUI1AOinhVCgwDHqirV8pBCLoTjWY';
    // const chatId = '-486075589';
    // fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=hello1234`);
    this.db
      .collection("messages")
      .add({
        imageUrl: imageurl,
        title: config.customTitle ? config.messageTitle : "Notificação",
        body: this.state.message,
        ...config,
        image: this.state.imageInput.name ? this.state.imageInput.name : "",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      })
      .then((ref) => {
        console.log("Added document with ID: ", ref.id);
        this.handleImageCancel();
      });
  };
  render() {
    const {
      messages,
      message,
      config,
      showMenu,
      imageUrl,
      scroll,
    } = this.state;

    return (
      <MessagesView
        messages={messages}
        message={message}
        handleChange={this.handleChange}
        handleSave={this.handleSave}
        showMenu={showMenu}
        config={config}
        handleSetConfig={this.handleSetConfig}
        imageUrl={imageUrl}
        handleImageCancel={this.handleImageCancel}
        fetchMessages={() => this.fetchMessagesNext()}
        scroll={scroll}
        handleHideMenu={this.handleHideMenu}
        handleOpenComments={this.handleOpenComments}
        openComments={this.state.openComments}
        messageComments={this.state.messageComments}
        setDisableAutoScroll={this.setDisableAutoScroll}
        disableAutoScroll={this.state.disableAutoScroll}
      />
    );
  }
}

export default MessagesContainer;

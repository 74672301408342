import React from "react";
// MUI
// COMPONENTS
import AppBar from "../AppBar";

export default function FullWidth(props) {
  return (
    <div className="fullwidth-layout">
      <AppBar />
      <div
        style={{ height: "100vh", paddingTop: 74, boxSizing: "border-box" }}
        className="body"
      >
        {props.children}
      </div>
    </div>
  );
}

import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function SwitchLabels({ label, name, handleChange, checked }) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={(event) => handleChange(name, event)}
            value={checked}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}

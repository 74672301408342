import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// COMPONENTS
import Drawer from "../Drawer";
// IMAGES
import appIcon from "../../images/logo512.png";
// FIREBASE
import firebase from "../../config/fbConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [barText, setBarText] = React.useState("... membros premium");

  React.useEffect(() => {
    fetchBarText();
  }, []);

  const fetchBarText = () => {
    // real-time listener
    firebase
      .firestore()
      .collection("appConfig")
      .doc("config1")
      .onSnapshot((snapshot) => {
        setBarText(snapshot.data().barText.value);
      });
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Drawer />
          <div style={{ display: "flex", margin: "8px 0px" }}>
            <img
              alt="logo"
              src={appIcon}
              style={{
                height: 51,
                borderRadius: "50%",
                marginRight: 14,
              }}
            />
            <div>
              <Typography variant="h6" align="left">
                Dicas Bet365 PREMIUM
              </Typography>
              <Typography variant="body1" align="left">
                {barText}
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

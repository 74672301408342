import React, { Component } from "react";
// COMPONENTS
import PostsView from "./posts-view";
import firebase from "../../config/fbConfig.js";

class PostsContainer extends Component {
  state = { posts: [], editPost: {}, editOpen: false, post: {} };
  db = firebase.firestore();
  componentDidMount() {
    this.fetchPosts();
  }
  fetchPosts() {
    // real-time listener
    let posts = [];
    this.db
      .collection("posts")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            let newPost = { ...change.doc.data(), id: change.doc.id };
            posts.push(newPost);
          }
          if (change.type === "removed") {
            let posts = this.state.posts;
            let index = posts.findIndex((element) => {
              if (element.id === change.doc.id) {
                return true;
              }
              return false;
            });
            posts.splice(index, 1);
            this.setState({ posts: posts });
          }
          if (change.type === "modified") {
            let posts = this.state.posts;
            let index = posts.findIndex((element) => {
              if (element.id === change.doc.id) {
                return true;
              }
              return false;
            });
            posts[index] = { ...change.doc.data(), id: change.doc.id };
            this.setState({ posts: posts });
          }
        });
        this.setState({ posts: posts });
      });
  }
  handleEditPost = (open, post) => {
    this.setState({ editOpen: open, editPost: post });
  };
  render() {
    return <PostsView {...this.state} handleEditPost={this.handleEditPost} />;
  }
}

export default PostsContainer;
